import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { QuestionnaireAnswers } from 'projects/shared/src/public-api';
import { Observable } from 'rxjs';
import { API_BASE, ApiResponse } from 'shared';

@Injectable()
export class CurrentSurveyApiService {

  constructor(
    @Inject(API_BASE) private apiBaseUrl: string,
    private httpClient: HttpClient,
  ) { }

  public submitAnswers(submission: QuestionnaireAnswers): Observable<ApiResponse<{}>> {
    return this.httpClient.post<ApiResponse<{}>>(`${this.apiBaseUrl}/submissions`, submission);
  }
}
