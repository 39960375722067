import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { combineLatest } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';
import {
  AccountStatus,
  AuthSelectors,
  BaseAppState,
  ObservableComponent,
  UserPreferencesActions,
  UserPreferencesSelectors,
  UsersApiActions,
  UserPreferences,
} from 'shared';

import { AddEmailModalComponent } from './core/auth/components/add-email-modal/add-email-modal.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent extends ObservableComponent implements OnInit {

  loggedInUserAccountStatus$ = this.store.pipe(select(AuthSelectors.getLoggedInUserAccountStatus));
  loggedInUserId$ = this.store.pipe(select(AuthSelectors.getLoggedInUserId));
  userPreferences$ = this.store.pipe(select(UserPreferencesSelectors.getUserPreferences));
  loggedInUserStudyCenterId$ = this.store.pipe(select(AuthSelectors.getLoggedInStudyCenterId));

  constructor(
    private store: Store<BaseAppState>,
    private bsModalService: BsModalService,
    private translateService: TranslateService,
  ) {
    super();
  }

  ngOnInit() {
    this.translateService.setDefaultLang('de');

    this.checkForEmailConfirmation();
    this.fetchData();
  }

  private fetchData() {
    combineLatest([
      this.loggedInUserId$,
      this.loggedInUserStudyCenterId$,
    ]).pipe(
      filter(([userId, centerId]: [string, string]) => Boolean(userId) && !Boolean(centerId)),
      take(1),
      tap(([userId, _centerId]: [string, string]) => this.store.dispatch(UsersApiActions.getUser({ userId: userId }))),
    ).subscribe();

    combineLatest([
      this.userPreferences$,
      this.loggedInUserId$,
    ]).pipe(
      filter(([userPreferences, userId]: [UserPreferences, string]) => Boolean(userId) && !Boolean(userPreferences)),
      take(1),
      tap(([_userPreferences, userId]: [UserPreferences, string]) => {
        this.store.dispatch(UserPreferencesActions.getUserSettings({ userId: userId }));
      }),
    ).subscribe();
  }

  private checkForEmailConfirmation() {
    combineLatest([
      this.loggedInUserAccountStatus$,
      this.userPreferences$,
    ]).pipe(
      filter(([accountStatus, userPreferences]: [AccountStatus | undefined, UserPreferences]) =>
        Boolean(userPreferences) &&
        userPreferences.showAddEmailModal &&
        Boolean(accountStatus) &&
        accountStatus !== AccountStatus.AwaitingEmailConfirmation &&
        accountStatus !== AccountStatus.EmailConfirmed &&
        accountStatus !== AccountStatus.Inactive,
      ),
      take(1),
      tap(() => this.bsModalService.show(AddEmailModalComponent)),
    ).subscribe();
  }

}
