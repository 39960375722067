import { Injectable } from '@angular/core';
import { QuestionnaireAnswers } from 'projects/shared/src/public-api';
import { Observable, of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { ApiResponse } from 'shared';

const submissions: QuestionnaireAnswers[] = [];

@Injectable()
export class CurrentSurveyMockApiService {

  public submitAnswers(submission: QuestionnaireAnswers): Observable<ApiResponse<{}>> {
    submissions.push(submission);
    const response: ApiResponse<{}> = {
      data: {},
      success: true,
      validationErrors: [],
      error: undefined,
    };

    return of(response).pipe(delay(1500));
  }
}
