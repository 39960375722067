import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { filter, map, take, takeUntil, tap } from 'rxjs/operators';
import {
  Answer,
  BaseAppState,
  ObservableComponent,
  Questionnaire,
  QuestionnaireAnswers,
  SurveysApiActions,
  SurveysSelectors,
} from 'shared';

import { goToSurvey } from '../../../core/router/actions/study-participation-navigation.actions';
import * as CurrentSurveyApiActions from '../../actions/current-survey-api.actions';
import * as CurrentSurveyActions from '../../actions/current-survey.actions';
import * as CurrentSurveySelectors from '../../selectors/current-survey.selectors';
import { getQuestionAmount } from '../../utilities/current-survey.utils';

@Component({
  selector: 'app-questionnaire-detail',
  templateUrl: './questionnaire-detail.component.html',
  styleUrls: ['./questionnaire-detail.component.scss'],
})
export class QuestionnaireDetailComponent extends ObservableComponent implements OnInit, OnDestroy {

  readonly processKeys = [
    SurveysApiActions.getQuestionnaire.type,
    CurrentSurveyApiActions.submitAnswers.type,
  ];

  questionnaire$ = this.store.pipe(select(SurveysSelectors.getActiveQuestionnaire));
  answers$ = this.store.pipe(select(CurrentSurveySelectors.getCurrentSurveyAnswers));
  currentQuestionIndex$ = this.store.pipe(select(CurrentSurveySelectors.getCurrentQuestionIndex));
  isSubmissionFinished$ = this.store.pipe(select(CurrentSurveySelectors.getIsSubmissionFinished));

  constructor(private store: Store<BaseAppState>) {
    super();
  }

  ngOnInit() {
    this.fetchQuestionnaire();
    this.observeSurvey();
  }

  ngOnDestroy() {
    this.submitAnswers(false);
    this.resetCurrentSurveyState();
  }

  handleResetClick() {
    this.resetCurrentSurveyState();
  }

  private fetchQuestionnaire() {
    this.questionnaire$.pipe(
      take(1),
      filter((questionnaire: Questionnaire) => !Boolean(questionnaire)),
      tap(() => this.store.dispatch(SurveysApiActions.getQuestionnaire())),
    ).subscribe();
  }

  private observeSurvey() {
    combineLatest([
      this.questionnaire$,
      this.currentQuestionIndex$,
    ]).pipe(
      filter(([questionnaire, _questionIndex]: [Questionnaire, number]) => Boolean(questionnaire)),
      tap(([questionnaire, questionIndex]: [Questionnaire, number]) => {
        const questionAmount = getQuestionAmount(questionnaire);
        if (questionIndex === questionAmount) {
          this.submitAnswers(true);
        }
      }),
      takeUntil(this.ngDestroy$),
    ).subscribe();
  }

  private submitAnswers(isCompleted: boolean) {
    const submission = this.makeSubmission(isCompleted);

    if (submission) {
      this.store.dispatch(CurrentSurveyApiActions.submitAnswers({ answers: submission }));
    } else {
      console.log('error while creating submission, redirecting to start');
      this.store.dispatch(goToSurvey());
    }
  }

  private makeSubmission(isCompleted: boolean): QuestionnaireAnswers | undefined {
    let submission: QuestionnaireAnswers | undefined;

    combineLatest([
      this.questionnaire$,
      this.answers$,
    ]).pipe(
      filter(([questionnaire, _answers]: [Questionnaire, { [questionId: string]: Answer }]) => Boolean(questionnaire)),
      take(1),
      map(([questionnaire, answers]: [Questionnaire, { [questionId: string]: Answer }]) =>  {
        submission = {
          questionnaireId: questionnaire.id,
          answers: answers,
          submittedAt: new Date().toISOString(),
          isCompleted: isCompleted,
        };
      }),
    ).subscribe();

    return submission;
  }

  private resetCurrentSurveyState() {
    this.store.dispatch(CurrentSurveyActions.resetCurrentSurveyState());
  }

}
