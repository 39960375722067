<div class="row justify-content-center d-flex flex-column align-items-center">
	<div class="col-md-10 col-lg-9 col-xl-8" [ngClass]="(screenHeight$ | async) >= 850 ? '' : 'replies'">
	  <ng-container *ngFor="let option of currentQuestion.choices">
        <div class="d-flex flex-column mt-3 justify-content-center">
          <button 
						class="btn btn-outline-primary btn-reply btn-lg mx-sm-2 my-sm-0"
						[class.worst]="getOptionsAssessment(option) === AnswerAssessment.Worst"
						[class.bad]="getOptionsAssessment(option) === AnswerAssessment.Bad"
						[class.good]="getOptionsAssessment(option) === AnswerAssessment.Good"
						[class.best]="getOptionsAssessment(option) === AnswerAssessment.Best"
						[class.selected]="selectedChoiceIds.has(option.id)"
						(click)="handleAnswerButtonClick(option.id)"
						>
						{{ option.label }}
					</button>
        </div>
	  </ng-container>
	</div>
	<div [ngClass]="(screenHeight$ | async) >= 850 ? 'col-md-10 col-lg-9 col-xl-8' : 'question-action-buttons border-top'">
		<div class="d-flex flex-row mt-4 mt-md-5 justify-content-even justify-content-center">
      <div *ngIf="!(isFirstQuestion$ | async)" class="col-6" [ngClass]="(screenHeight$ | async) >= 850 ? '' : 'col-md-4 col-lg-3'">
				<button class="btn btn-lg button-back w-100" (click)="handleBackClick()">{{'application.back' | translate}}</button>
			</div>
      <div class="col-6" [ngClass]="(screenHeight$ | async) >= 850 ? '' : 'col-md-4 col-lg-3'">
				<button class="btn btn-lg button-next w-100" [disabled]="!canSubmit()" (click)="handleSubmitAnswerButtonClick()">{{(!(isLastQuestion$ | async) ? 'application.next' : 'application.finish') | translate}}</button>
			</div>
		</div>
	</div>
</div>
