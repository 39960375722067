import { Question, QuestionGroup, Questionnaire } from 'shared';

export function getQuestions(questionnaire: Questionnaire | undefined) {
  const questions: Question[] = [];

  questionnaire?.questionGroups.forEach((questionGroup: QuestionGroup) => {
    questions.push(...questionGroup.questions);
  });

  return questions;
}

export function getQuestionAmount(questionnaire: Questionnaire) {
  const questions = getQuestions(questionnaire);

  return questions.length;
}
