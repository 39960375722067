<div class="container">
  <ng-container *ngIf="loggedInUser$ | async as user">
    <h2>{{'application.welcome' | translate}} {{user.lastLogin ? ('application.back-welcome' | translate) : ''}}!</h2>

    <div class="d-flex row">
      <span class="col-6" *ngIf="user.lastLogin">{{'auth.last-login' | translate}}: {{ user.lastLogin | date: 'dd.MM.yyyy' }}</span>
      <div
        *ngIf="!user.email"
        class="missing-mail-notification d-flex align-items-center col-6 justify-content-end"
        (click)="handleGoToProfilePageClick()"
        >
        <span class="material-icons">info</span>
        <span class="ml-2">{{'users.add-e-mail' | translate}}</span>
      </div>
    </div>

    <div class="d-flex mt-5 justify-content-center">
      <button
        class="btn btn-outline-secondary"
        (click)="handleGoToParticipantDetailsClick(user)"
        >
        {{'navigation.my-submissions' | translate}}
      </button>
    </div>
    <div class="d-flex mt-4 justify-content-center">
      <button
        class="btn btn-primary"
        (click)="handleGoToQuestionnaireClick()"
        >
        {{'navigation.new-submission' | translate}}
      </button>
    </div>
  </ng-container>
</div>
