<div class="row justify-content-center d-flex flex-column align-items-center">
  <div class="col-md-10 col-lg-9 col-xl-8">
  <form [formGroup]="form" name="answers" #ngForm="ngForm" (ngSubmit)="handleSubmitAnswerButtonClick()" novalidate>
      <input type="number" class="form-control form-control-lg text-center" formControlName="choice">
      <div *ngIf="ngForm.submitted && !form.valid" class="custom-invalid-feedback" [ngClass]="(screenHeight$ | async) >= 850 ? '' : 'replies'">
        <div *ngIf="form.controls['choice'].hasError('nonInteger')">Bitte geben Sie eine ganze Zahl ein<br></div>
        <div *ngIf="form.controls['choice'].hasError('min')">Zahl darf nicht kleiner als {{currentQuestion.options.min}} sein<br></div>
        <div *ngIf="form.controls['choice'].hasError('max')">Zahl darf nicht größer als {{currentQuestion.options.max}} sein<br></div>
      </div>
      <div [ngClass]="(screenHeight$ | async) >= 850 ? 'col-md-10 col-lg-9 col-xl-8' : 'question-action-buttons border-top'">
        <div class="d-flex flex-row mt-4 mt-md-5 justify-content-even justify-content-center">
          <div *ngIf="!(isFirstQuestion$ | async)" class="col-6" [ngClass]="(screenHeight$ | async) >= 850 ? '' : 'col-md-4 col-lg-3'">
            <button class="btn btn-lg button-back" (click)="handleBackClick()">Zurück</button>
          </div>
          <div class="col-6" [ngClass]="(screenHeight$ | async) >= 850 ? '' : 'col-md-4 col-lg-3'">
            <button class="btn btn-lg button-next" [disabled]="!form.controls['choice'].value && form.controls['choice'].value !== 0">{{!(isLastQuestion$ | async) ? 'Weiter' : 'Beenden'}}</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
