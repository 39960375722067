import { Routes } from '@angular/router';
import { LoggedInGuard } from 'shared';

import {
  QuestionnaireDetailComponent,
} from '../../current-survey/components/questionnaire-detail/questionnaire-detail.component';
import { ParticipantDetailsComponent } from '../../participant/components/participant-details/participant-details.component';
import { DashboardComponent } from '../../current-survey/components/dashboard/dashboard.component';
import { SURVEY_PATH_ELEMENTS } from './definitions/study-participation-navigation.definitions';

export const routes: Routes = [
  {
    path: SURVEY_PATH_ELEMENTS.rootElement,
    children: [
      {
        path: SURVEY_PATH_ELEMENTS.root.startElement,
        pathMatch: 'full',
        component: DashboardComponent,
        canActivate: [LoggedInGuard],
      },
      {
        path: SURVEY_PATH_ELEMENTS.root.questionnaireElement,
        pathMatch: 'full',
        component: QuestionnaireDetailComponent,
        canActivate: [LoggedInGuard],
      },
    ],
  },
  {
    path: SURVEY_PATH_ELEMENTS.participantDetailsRoot,
    canActivate: [LoggedInGuard],
    children: [
      {
        path: SURVEY_PATH_ELEMENTS.participantDetails.participantDetailsElement,
        pathMatch: 'full',
        component: ParticipantDetailsComponent,
      },
    ],
  },
];
