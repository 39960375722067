<div class="mt-5" [ngClass]="(screenHeight$ | async) >= 850 ? '' : 'replies'">
  <h2>
    <mark>{{questionGroup.title}}</mark>
  </h2>
  <p class="mt-4">{{currentQuestion.label}}</p>

  <ng-container *ngIf="currentQuestion.imageUrl">
    <div class="d-flex justify-content-center mt-4">
      <img src="{{currentQuestion.imageUrl}}">
    </div>
  </ng-container>
</div>

<div class="row justify-content-even d-flex flex-column align-items-center" [ngClass]="(screenHeight$ | async) >= 850 ? '' : 'question-action-buttons info border-top'">
  <div class="col-md-10 col-lg-9 col-xl-8 justify-content-center">
    <div class="d-flex flex-row mt-4 mt-md-5" [ngClass]="(isFirstQuestion$ | async) ? 'justify-content-center' : 'justify-content-even'">
      <div *ngIf="!(isFirstQuestion$ | async)" class="col-6">
        <button class="btn btn-lg button-back w-100" (click)="handleBackClick()">{{'application.back' | translate}}</button>
      </div>
      <div [class.col-6]="!(isFirstQuestion$ | async)">
        <button class="btn btn-lg button-accept w-100" (click)="handleNextClick()">{{'application.understood' | translate}}</button>
      </div>
    </div>
  </div>
</div>
