import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';

import { StudyParticipationNavigationEffects } from './effects/study-participation-navigation.effects';
import { routes } from './study-participation.routes';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    EffectsModule.forFeature([
      StudyParticipationNavigationEffects,
    ]),
  ],
})
export class StudyParticipationRouterModule { }
