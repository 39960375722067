import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { SubmissionsNavigationActions } from 'shared';

import * as StudyParticipationNavigationActions from '../actions/study-participation-navigation.actions';
import { SURVEY_PATHS } from '../definitions/study-participation-navigation.definitions';

@Injectable()
export class StudyParticipationNavigationEffects {

  goToSurvey$ = createEffect(() => this.actions$.pipe(
    ofType(StudyParticipationNavigationActions.goToSurvey),
    tap(() => this.router.navigate([SURVEY_PATHS.root.questionnaire]))),
    { dispatch: false },
  );

  goToDashboard$ = createEffect(() => this.actions$.pipe(
    ofType(StudyParticipationNavigationActions.goToDashboard),
    tap(() => this.router.navigate([SURVEY_PATHS.root.start]))),
    { dispatch: false },
  );

  goToParticipantDetails$ = createEffect(() => this.actions$.pipe(
    ofType(SubmissionsNavigationActions.goToParticipantDetails),
    tap((action) => this.router.navigate([SURVEY_PATHS.participantDetails.patient(action.participantId)]))),
   { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private router: Router,
  ) { }
}
