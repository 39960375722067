import { Component, HostListener, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, NgForm } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Answer } from 'projects/shared/src/public-api';
import { of } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';
import { BaseAppState, numberValidator, Question } from 'shared';

import * as CurrentSurveyActions from '../../../actions/current-survey.actions';
import * as CurrentSurveySelectors from '../../../selectors/current-survey.selectors';

interface NumberAnswersFormValue {
  choice: number | null | undefined;
}

@Component({
  selector: 'app-number-answers',
  templateUrl: './number-answers.component.html',
  styleUrls: ['./number-answers.component.scss'],
})
export class NumberAnswersComponent implements OnChanges, OnInit {

  @Input() currentQuestion: Question;
  @ViewChild('ngForm', { static: false }) ngForm: NgForm;

  preselectedAnswer$ = this.store.pipe(select(CurrentSurveySelectors.getCurrentSurveyAnswerForQuestion));
  isFirstQuestion$ = this.store.pipe(select(CurrentSurveySelectors.getIsFirstQuestion));
  isLastQuestion$ = this.store.pipe(select(CurrentSurveySelectors.getIsLastQuestion));
  screenHeight$ = of(window.innerHeight);

  form: FormGroup = new FormGroup({
    choice: new FormControl(undefined, [numberValidator(undefined, undefined)]),
  });
  get formValue(): NumberAnswersFormValue {
    return this.form.value as NumberAnswersFormValue;
  }
  set formValue(formValue: NumberAnswersFormValue) {
    this.form.setValue(formValue);
  }

  @HostListener('window:resize', ['$event'])
  onResize(_event: unknown) {
    this.screenHeight$ = of(window.innerHeight);
  }

  constructor(private store: Store<BaseAppState>) { }

  ngOnInit() {
    this.updateValidators();
  }

  ngOnChanges() {
    this.initializeSelectedChoices();
  }

  handleSubmitAnswerButtonClick() {
    if (!this.form.valid) {
      return;
    }

    const answer: Answer = {
      choices: [this.formValue.choice!.toString()],
      answeredAt: new Date().toISOString(),
    };

    this.store.dispatch(CurrentSurveyActions.goToNextQuestion({
      currentQuestionId: this.currentQuestion.id,
      answer: answer,
    }));
  }

  handleBackClick() {
    this.store.dispatch(CurrentSurveyActions.goToPreviousQuestion());
  }

  handleSkipButtonClick() {
    this.store.dispatch(CurrentSurveyActions.goToNextQuestion({
      currentQuestionId: this.currentQuestion.id,
      answer: undefined,
    }));
  }

  private initializeSelectedChoices() {
    if (this.ngForm) {
      this.ngForm.resetForm();
    }

    this.preselectedAnswer$.pipe(
      filter((answer: Answer | undefined) => Boolean(answer)),
      take(1),
      tap((answer: Answer) => {
        this.formValue = {
          choice: answer && answer.choices.length > 0 ? parseInt(answer.choices[0], 10) : null,
        };
      }),
    ).subscribe();
  }

  private updateValidators() {
    this.form.controls['choice'].setValidators(numberValidator(this.currentQuestion.options?.min, this.currentQuestion.options?.max));
  }

}
