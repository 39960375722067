<form [formGroup]="addEmailForm" (ngSubmit)="handleAddEmailClick()" #ngForm="ngForm" novalidate>
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">{{'users.add-e-mail' | translate}}</h5>
    <button type="button" class="close" (click)="handleCloseClick()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>{{'users.add-e-mail-message' | translate}}</p>

    <div class="form-group">
      <label for="email">{{'users.e-mail' | translate}}:</label>
      <input #email formControlName="email" type="text" class="form-control" placeholder="{{'users.e-mail' | translate}}" [class.is-invalid]="ngForm.submitted && addEmailForm.controls['email'].invalid"/>
      <div class="invalid-feedback">
        <div *ngIf="addEmailForm.controls['email'].hasError('pattern')">{{'error.email-invalid' | translate}}</div>
      </div>
    </div>

  </div>
  <div class="modal-footer justify-content-between">
    <div>
      <button type="button" class="btn btn-outline-secondary" (click)="handleDontShowAgainClick()">{{'application.do-not-show-again' | translate}}</button>
    </div>
    <div>
      <button type="button" class="btn btn-secondary mr-2" (click)="handleCloseClick()">{{'application.cancel' | translate}}</button>
      <button type="submit" class="btn btn-primary">{{'users.add-e-mail' | translate}}</button>
    </div>
  </div>
</form>
