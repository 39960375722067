import { props } from '@ngrx/store';
import { createAsyncAction, QuestionnaireAnswers } from 'shared';

export enum CurrentSurveyApiActionTypes {
  SubmitAnswers = '[Current Survey API] Submit answers',
  SubmitAnswersSuccess = '[Current Survey API] Submit answers success',
  SubmitAnswersFail = '[Current Survey API] Submit answers fail',
}

export const submitAnswers = createAsyncAction(
  CurrentSurveyApiActionTypes.SubmitAnswers,
  {
    asyncKey: CurrentSurveyApiActionTypes.SubmitAnswers,
    asyncState: 'start',
  },
  props<{ answers: QuestionnaireAnswers }>(),
);

export const submitAnswersSuccess = createAsyncAction(
  CurrentSurveyApiActionTypes.SubmitAnswersSuccess,
  {
    asyncKey: CurrentSurveyApiActionTypes.SubmitAnswers,
    asyncState: 'success',
  },
);

export const submitAnswersFail = createAsyncAction(
  CurrentSurveyApiActionTypes.SubmitAnswersFail ,
  {
    asyncKey: CurrentSurveyApiActionTypes.SubmitAnswers,
    asyncState: 'fail',
  },
);
