import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AsyncStateOverlayModule, SubmissionsModule } from 'shared';

import { ParticipantDetailsComponent } from './components/participant-details/participant-details.component';

@NgModule({
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    AsyncStateOverlayModule,
    SubmissionsModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  declarations: [
    ParticipantDetailsComponent,
  ],
})
export class ParticipantModule { }

function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
