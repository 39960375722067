import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { AsyncStateOverlayModule } from 'shared';

import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ButtonAnswersComponent } from './components/question-answers/button-answers/button-answers.component';
import { DateAnswerComponent } from './components/question-answers/date-answer/date-answer.component';
import { NumberAnswersComponent } from './components/question-answers/number-answers/number-answers.component';
import { QuestionInfoComponent } from './components/question-answers/question-info/question-info.component';
import { SliderAnswersComponent } from './components/question-answers/slider-answers/slider-answers.component';
import { QuestionDetailComponent } from './components/question-detail/question-detail.component';
import { QuestionnaireDetailComponent } from './components/questionnaire-detail/questionnaire-detail.component';
import {
  QuestionnaireProgressBarComponent,
} from './components/questionnaire-progress-bar/questionnaire-progress-bar.component';
import { CURRENT_SURVEY_STATE_KEY } from './definitions/current-survey.definitions';
import { CurrentSurveyEffects } from './effects/current-survey.effects';
import { currentSurveyReducer } from './reducers/current-survey.reducer';
import { CurrentSurveyApiService } from './services/current-survey-api.service';
import { CurrentSurveyMockApiService } from './services/current-survey-mock-api.service';

@NgModule({
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    AsyncStateOverlayModule,
    StoreModule.forFeature(CURRENT_SURVEY_STATE_KEY, currentSurveyReducer),
    EffectsModule.forFeature([
      CurrentSurveyEffects,
    ]),
    ProgressbarModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
  ],
  declarations: [
    QuestionnaireDetailComponent,
    QuestionDetailComponent,
    ButtonAnswersComponent,
    SliderAnswersComponent,
    NumberAnswersComponent,
    DateAnswerComponent,
    DashboardComponent,
    QuestionInfoComponent,
    QuestionnaireProgressBarComponent,
  ],
  providers: [
    {
      provide: CurrentSurveyApiService,
      // useClass: CurrentSurveyApiService,
      useClass: CurrentSurveyMockApiService,
    },
  ],
})
export class CurrentSurveyModule { }

function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
