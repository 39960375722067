import { Component, HostListener, Input, OnChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup, NgForm } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';
import { Answer, BaseAppState, dateStringValidator, Question } from 'shared';

import * as CurrentSurveyActions from '../../../actions/current-survey.actions';
import * as CurrentSurveySelectors from '../../../selectors/current-survey.selectors';

interface DateAnswerFormValue {
  choice: string | null | undefined;
}

@Component({
  selector: 'app-date-answer',
  templateUrl: './date-answer.component.html',
  styleUrls: ['./date-answer.component.scss'],
})
export class DateAnswerComponent implements OnChanges {

  @Input() currentQuestion: Question;

  @ViewChild('ngForm', { static: false }) ngForm: NgForm;

  preselectedAnswer$ = this.store.pipe(select(CurrentSurveySelectors.getCurrentSurveyAnswerForQuestion));
  isFirstQuestion$ = this.store.pipe(select(CurrentSurveySelectors.getIsFirstQuestion));
  isLastQuestion$ = this.store.pipe(select(CurrentSurveySelectors.getIsLastQuestion));
  screenHeight$ = of(window.innerHeight);

  form: FormGroup = new FormGroup({
    choice: new FormControl(undefined, [dateStringValidator()]),
  });
  get formValue(): DateAnswerFormValue {
    return this.form.value as DateAnswerFormValue;
  }
  set formValue(formValue: DateAnswerFormValue) {
    this.form.setValue(formValue);
  }

  @HostListener('window:resize', ['$event'])
  onResize(_event: unknown) {
    this.screenHeight$ = of(window.innerHeight);
  }

  constructor(private store: Store<BaseAppState>) { }

  ngOnChanges() {
    this.initializeSelectedChoices();
  }

  handleSubmitAnswerButtonClick() {
    if (!this.form.valid) {
      return;
    }

    const answer: Answer = {
      choices: [this.formValue.choice!],
      answeredAt: new Date().toISOString(),
    };

    this.store.dispatch(CurrentSurveyActions.goToNextQuestion({
      currentQuestionId: this.currentQuestion.id,
      answer: answer,
    }));
  }

  handleBackClick() {
    this.store.dispatch(CurrentSurveyActions.goToPreviousQuestion());
  }

  handleSkipButtonClick() {
    this.store.dispatch(CurrentSurveyActions.goToNextQuestion({
      currentQuestionId: this.currentQuestion.id,
      answer: undefined,
    }));
  }

  private initializeSelectedChoices() {
    if (this.ngForm) {
      this.ngForm.resetForm();
    }

    this.preselectedAnswer$.pipe(
      filter((answer: Answer | undefined) => Boolean(answer)),
      take(1),
      tap((answer: Answer) => {
        this.formValue = {
          choice: answer && answer.choices.length > 0 ? answer.choices[0] : null,
        };
      }),
    ).subscribe();
  }

}
