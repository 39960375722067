import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { UpdateUserPreferencesRequest } from 'dist/shared/lib/core/user-preferences/models';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { take, tap } from 'rxjs/operators';
import {
  AuthSelectors,
  BaseAppState,
  BaseUser,
  EditUserRequest,
  emailPatternValidator,
  UserPreferencesActions,
  UsersApiActions,
} from 'shared';

@Component({
  selector: 'app-add-email-modal',
  templateUrl: './add-email-modal.component.html',
  styleUrls: ['./add-email-modal.component.scss'],
})
export class AddEmailModalComponent {

  loggedInUser$ = this.store.pipe(select(AuthSelectors.getLoggedInUser));

  addEmailForm = new FormGroup({
    email: new FormControl('', [emailPatternValidator, Validators.required]),
  });

  constructor(
    private store: Store<BaseAppState>,
    private modalRef: BsModalRef,
  ) { }

  public handleAddEmailClick() {
    if (this.addEmailForm.invalid) {
      return;
    }

    const editUserRequest: EditUserRequest = {
      email: this.addEmailForm.controls['email'].value,
    };

    this.loggedInUser$.pipe(
      take(1),
      tap((user: BaseUser) => {
        this.store.dispatch(UsersApiActions.editUser({ userId: user.id, request: editUserRequest }));
        this.close();
      }),
    ).subscribe();
  }

  public handleDontShowAgainClick() {
    const request: UpdateUserPreferencesRequest = {
      showAddEmailModal: false,
    };

    this.loggedInUser$.pipe(
      take(1),
      tap((user: BaseUser) => {
        this.store.dispatch(UserPreferencesActions.updateUserSettings({ userId: user.id, request: request }));
        this.close();
      }),
    ).subscribe();
  }

  public handleCloseClick() {
    this.close();
  }

  private close() {
    this.modalRef.hide();
  }

}
