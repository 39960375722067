<ng-container *ngIf="currentQuestion$ | async as question">
  <ng-container *ngIf="question.type !== QUESTION_TYPE.Info">
    <div class="row justify-content-center text-center d-flex flex-column align-items-center mt-3">
      <div class="col-xl-7 col-lg-8 col-md-10">
        <app-questionnaire-progress-bar></app-questionnaire-progress-bar>
        <h5 *ngIf="isLastQuestion$ | async">{{'application.last-question' | translate}}</h5>
        <h4 class="text-prewrap">{{ question.label }}</h4>
        
        <ng-container *ngIf="question.imageUrl">
          <img src="{{question.imageUrl}}">
        </ng-container>
      </div>
    </div>
  </ng-container>

  <ng-container [ngSwitch]="question.type">

    <ng-container *ngSwitchCase="QUESTION_TYPE.Info">
      <ng-container *ngIf="questionGroup$ | async as questionGroup">
        <app-question-info
          [currentQuestion]="question"
          [questionGroup]="questionGroup"
          >
        </app-question-info>
      </ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="QUESTION_TYPE.SingleSelect">
      <app-button-answers
        [currentQuestion]="question"
        >
      </app-button-answers>
    </ng-container>

    <ng-container *ngSwitchCase="QUESTION_TYPE.MultiSelect">
      <app-button-answers
        [currentQuestion]="question"
        [allowMultiSelect]="true"
        >
      </app-button-answers>
    </ng-container>

    <ng-container *ngSwitchCase="QUESTION_TYPE.SingleSelectSlider">
      <app-slider-answers
        [currentQuestion]="question"
        >
      </app-slider-answers>
    </ng-container>

    <ng-container *ngSwitchCase="QUESTION_TYPE.Number">
      <app-number-answers
        [currentQuestion]="question"
        >
      </app-number-answers>
    </ng-container>

    <ng-container *ngSwitchCase="QUESTION_TYPE.Date">
      <app-date-answer
        [currentQuestion]="question"
        >
      </app-date-answer>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <span>{{'application.no-answers-for-question' | translate}}.</span>
    </ng-container>

  </ng-container>

</ng-container>
