import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { Answer, Question, QuestionGroup, Questionnaire, SurveysSelectors } from 'shared';

import { CURRENT_SURVEY_STATE_KEY } from '../definitions/current-survey.definitions';
import { CurrentSurveyState } from '../reducers/current-survey.reducer';
import { getQuestionAmount, getQuestions } from '../utilities/current-survey.utils';

export const getCurrentSurveyState = createFeatureSelector<CurrentSurveyState>(CURRENT_SURVEY_STATE_KEY);

export const getCurrentQuestionIndex: MemoizedSelector<object, number> = createSelector(
  getCurrentSurveyState,
  (state: CurrentSurveyState) => state.currentQuestionIndex,
);

export const getCurrentQuestion: MemoizedSelector<object, Question | undefined> = createSelector(
  getCurrentQuestionIndex,
  SurveysSelectors.getActiveQuestionnaire,
  (index: number, questionnaire: Questionnaire | undefined) => {
    const questions = getQuestions(questionnaire);
    return questionnaire && questions[index];
  },
);

export const getCurrentSurveyAnswers: MemoizedSelector<object, { [questionId: string]: Answer }> = createSelector(
  getCurrentSurveyState,
  (state: CurrentSurveyState) => state.answers,
);

export const getCurrentSurveyAnswerForQuestion: MemoizedSelector<object, Answer | undefined> = createSelector(
  getCurrentQuestion,
  getCurrentSurveyAnswers,
  (question: Question, answers: { [questionId: string]: Answer }) => question && answers[question.id],
);

export const getIsFirstQuestion: MemoizedSelector<object, boolean> = createSelector(
  getCurrentQuestionIndex,
  (index: number) => index === 0,
);

export const getIsLastQuestion: MemoizedSelector<object, boolean> = createSelector(
  getCurrentQuestionIndex,
  SurveysSelectors.getActiveQuestionnaire,
  (index: number, questionnaire: Questionnaire) => {
    const questionAmount = getQuestionAmount(questionnaire);
    return index === questionAmount - 1;
  },
);

export const getIsSubmissionFinished: MemoizedSelector<object, boolean> = createSelector(
  getCurrentSurveyState,
  (state: CurrentSurveyState) => state.submissionFinished,
);

export const getQuestionGroupByQuestion = (questionId: string) => createSelector(
  SurveysSelectors.getActiveQuestionnaire,
  (questionnaire: Questionnaire) => {
    if (!questionnaire) {
      return undefined;
    }

    let questionGroup: QuestionGroup | undefined;

    questionnaire.questionGroups.forEach((qG: QuestionGroup) => {
      const foundQuestion = qG.questions.find(q => q.id === questionId);

      if (foundQuestion) {
        questionGroup = qG;
      }
    });

    return questionGroup;
  },
);
