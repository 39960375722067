import { Component } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { filter, switchMap, take } from 'rxjs/operators';
import { BaseAppState, Question, QuestionType } from 'shared';

import * as CurrentSurveySelectors from '../../selectors/current-survey.selectors';

@Component({
  selector: 'app-question-detail',
  templateUrl: './question-detail.component.html',
  styleUrls: ['./question-detail.component.scss'],
})
export class QuestionDetailComponent {

  currentQuestion$ = this.store.pipe(select(CurrentSurveySelectors.getCurrentQuestion));
  isLastQuestion$ = this.store.pipe(select(CurrentSurveySelectors.getIsLastQuestion));
  questionGroup$ = this.currentQuestion$.pipe(
    filter((question: Question | undefined) => Boolean(question)),
    take(1),
    switchMap((question: Question) => this.store.pipe(select(CurrentSurveySelectors.getQuestionGroupByQuestion(question.id)))),
  );

  QUESTION_TYPE = QuestionType;

  constructor(private store: Store<BaseAppState>) { }

}
