import { Action, createReducer, on } from '@ngrx/store';
import { Answer } from 'shared';

import * as CurrentSurveyApiActions from '../actions/current-survey-api.actions';
import * as CurrentSurveyActions from '../actions/current-survey.actions';

export interface CurrentSurveyState {
  currentQuestionIndex: number;
  answers: { [questionId: string]: Answer };
  submissionFinished: boolean;
}

const initialState: CurrentSurveyState = {
  currentQuestionIndex: 0,
  answers: {},
  submissionFinished: false,
};

const reducer = createReducer(
  initialState,
  on(
    CurrentSurveyActions.goToNextQuestion,
    (state: CurrentSurveyState, { currentQuestionId, answer }) => handleGoToNextQuestion(
      state,
      currentQuestionId,
      answer,
    ),
  ),
  on(
    CurrentSurveyActions.goToPreviousQuestion,
    (state: CurrentSurveyState) => handleGoToPreviousQuestion(state),
  ),
  on(
    CurrentSurveyApiActions.submitAnswersSuccess,
    () => ({
      currentQuestionIndex: 0,
      answers: {},
      submissionFinished: true,
    }),
  ),
  on(
    CurrentSurveyActions.resetCurrentSurveyState,
    () => initialState,
  ),
);

export function currentSurveyReducer(state = initialState, action: Action): CurrentSurveyState {
  return reducer(state, action);
}

function handleGoToNextQuestion(
  state: CurrentSurveyState,
  currentQuestionId: string,
  answer: Answer | undefined,
): CurrentSurveyState {
  const answers = { ...state.answers };
  delete answers[currentQuestionId];

  if (answer) {
    answers[currentQuestionId] = answer;
  }

  return {
    ...state,
    currentQuestionIndex: state.currentQuestionIndex + 1,
    answers: answers,
  };
}

function handleGoToPreviousQuestion(state: CurrentSurveyState): CurrentSurveyState {
  if (state.currentQuestionIndex <= 0) {
    return state;
  }

  return {
    ...state,
    currentQuestionIndex: state.currentQuestionIndex - 1,
  };
}

