import { Component, HostListener, Input, OnChanges } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BaseAppState } from 'projects/shared/src/public-api';
import { of } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { Answer, Choice, Question } from 'shared';

import * as CurrentSurveyActions from '../../../actions/current-survey.actions';
import * as CurrentSurveySelectors from '../../../selectors/current-survey.selectors';

@Component({
  selector: 'app-slider-answers',
  templateUrl: './slider-answers.component.html',
  styleUrls: ['./slider-answers.component.scss'],
})
export class SliderAnswersComponent implements OnChanges {

  @Input() currentQuestion: Question;

  preselectedAnswer$ = this.store.pipe(select(CurrentSurveySelectors.getCurrentSurveyAnswerForQuestion));
  isFirstQuestion$ = this.store.pipe(select(CurrentSurveySelectors.getIsFirstQuestion));
  isLastQuestion$ = this.store.pipe(select(CurrentSurveySelectors.getIsLastQuestion));
  screenHeight$ = of(window.innerHeight);

  sliderIsTouched = false;
  selectedIndex = 0;

  @HostListener('window:resize', ['$event'])
  onResize(_event: unknown) {
    this.screenHeight$ = of(window.innerHeight);
  }

  constructor(private store: Store<BaseAppState>) { }

  ngOnChanges() {
    this.initializeSlider();
  }

  onSliderValueChange(value: number | null) {
    this.sliderIsTouched = true;
    this.selectedIndex = Number(value);
  }

  handleSliderValueConfirm() {
    if (this.selectedIndex === undefined) {
      this.store.dispatch(CurrentSurveyActions.goToNextQuestion({
        currentQuestionId: this.currentQuestion.id,
        answer: undefined,
      }));
    } else {
      const selectedChoice: Choice = this.currentQuestion.choices[this.selectedIndex];
      const answer: Answer = {
        choices: [selectedChoice.id],
        answeredAt: new Date().toISOString(),
      };

      this.store.dispatch(CurrentSurveyActions.goToNextQuestion({
        currentQuestionId: this.currentQuestion.id,
        answer: answer,
      }));
    }
  }

  handleBackClick() {
    this.store.dispatch(CurrentSurveyActions.goToPreviousQuestion());
  }

  handleSkipButtonClick() {
    this.store.dispatch(CurrentSurveyActions.goToNextQuestion({
      currentQuestionId: this.currentQuestion.id,
      answer: undefined,
    }));
  }

  private initializeSlider() {
    this.preselectedAnswer$.pipe(
      take(1),
      tap((preselectedAnswer: Answer) => {
        const preselectedChoiceIndex = this.getPreselectedChoiceIndex(preselectedAnswer);

        if (preselectedChoiceIndex !== undefined) {
          this.selectedIndex = preselectedChoiceIndex;
        } else if (this.isValidNumber(preselectedChoiceIndex)) {
          this.selectedIndex = preselectedChoiceIndex!;
        } else {
          this.selectedIndex = 0;
        }

        this.sliderIsTouched = false;
      }),
    ).subscribe();
  }

  private getPreselectedChoiceIndex(preselectedAnswer: Answer | undefined): number | undefined {
    if (!preselectedAnswer || preselectedAnswer.choices.length === 0) {
      return undefined;
    }

    const preselectedChoiceId = preselectedAnswer.choices[0];
    const choiceIndex = this.currentQuestion.choices.findIndex((c: Choice) => c.id === preselectedChoiceId);
    if (choiceIndex === -1) {
      return undefined;
    }

    return choiceIndex;
  }

  private isValidNumber(number: number | undefined): boolean {
    return Boolean(number) || number === 0;
  }

}
