import { Component } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';
import { BaseAppState, Question, QuestionGroup, Questionnaire, QuestionType, SurveysSelectors } from 'shared';

import * as CurrentSurveySelectors from '../../selectors/current-survey.selectors';

@Component({
  selector: 'app-questionnaire-progress-bar',
  templateUrl: './questionnaire-progress-bar.component.html',
  styleUrls: ['./questionnaire-progress-bar.component.scss'],
})
export class QuestionnaireProgressBarComponent {

  questionnaire$ = this.store.pipe(select(SurveysSelectors.getActiveQuestionnaire));
  overallQuestionIndex$ = this.store.pipe(select(CurrentSurveySelectors.getCurrentQuestionIndex));
  currentQuestion$ = this.store.pipe(select(CurrentSurveySelectors.getCurrentQuestion));

  currentQuestionGroup$ = this.currentQuestion$.pipe(
    filter((question: Question) => Boolean(question)),
    switchMap((question: Question) => this.store.pipe(select(CurrentSurveySelectors.getQuestionGroupByQuestion(question.id)))),
  );

  currentQuestionIndexInQuestionGroup$ = combineLatest([
    this.currentQuestion$,
    this.currentQuestionGroup$,
  ]).pipe(
    filter(([question, questionGroup]: [Question, QuestionGroup]) => Boolean(question) && Boolean(questionGroup)),
    map(([question, questionGroup]: [Question, QuestionGroup]) => questionGroup.questions.findIndex((q: Question) => question.id === q.id)),
  );

  questionAmount$ = this.questionnaire$.pipe(
    filter((questionnaire: Questionnaire) => Boolean(questionnaire)),
    map((questionnaire: Questionnaire) => {
      let amount = 0;

      questionnaire.questionGroups.forEach((group: QuestionGroup) => {
        amount += group.questions.length;
      });

      return amount;
    }),
  );

  currentQuestionGroupQuestionAmount$ = this.currentQuestionGroup$.pipe(
    filter((questionGroup: QuestionGroup) => Boolean(questionGroup)),
    map((group: QuestionGroup) => {
      const infoQuestionAmount = group.questions.filter((q: Question) => q.type === QuestionType.Info).length;
      return group.questions.length - infoQuestionAmount;
    }),
  );

  constructor(private store: Store<BaseAppState>) { }
}
