import { Component } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AuthSelectors, BaseAppState, Participant, ProfilePageComponent, SubmissionsNavigationActions } from 'shared';

import { goToSurvey } from '../../../core/router/actions/study-participation-navigation.actions';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent {

  loggedInUser$ = this.store.pipe(select(AuthSelectors.getLoggedInUser));

  constructor(
    private store: Store<BaseAppState>,
    private modalService: BsModalService,
  ) { }

  handleGoToQuestionnaireClick() {
    this.store.dispatch(goToSurvey());
  }

  handleGoToParticipantDetailsClick(user: Participant) {
    this.store.dispatch(SubmissionsNavigationActions.goToParticipantDetails({ participantId: user.id }));
  }

  handleGoToProfilePageClick() {
    this.modalService.show(ProfilePageComponent);
  }

}
