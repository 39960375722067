import { Routes } from '@angular/router';

import { SURVEY_PATHS } from './core/router/definitions/study-participation-navigation.definitions';

export const routes: Routes = [
  {
    path: '**',
    redirectTo: SURVEY_PATHS.root.start,
  },
];
