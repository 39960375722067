import { Component, HostListener, Input } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { BaseAppState } from 'projects/shared/src/lib/core/store/reducers';
import { Question, QuestionGroup } from 'projects/shared/src/lib/models';
import { of } from 'rxjs';

import * as CurrentSurveyActions from '../../../actions/current-survey.actions';
import * as CurrentSurveySelectors from '../../../selectors/current-survey.selectors';

@Component({
  selector: 'app-question-info',
  templateUrl: './question-info.component.html',
  styleUrls: ['./question-info.component.scss'],
})
export class QuestionInfoComponent {

  @Input() currentQuestion: Question;
  @Input() questionGroup: QuestionGroup;

  isFirstQuestion$ = this.store.pipe(select(CurrentSurveySelectors.getIsFirstQuestion));
  isLastQuestion$ = this.store.pipe(select(CurrentSurveySelectors.getIsLastQuestion));
  screenHeight$ = of(window.innerHeight);

  @HostListener('window:resize', ['$event'])
  onResize(_event: unknown) {
    this.screenHeight$ = of(window.innerHeight);
  }

  constructor(private store: Store<BaseAppState>) { }

  handleNextClick() {
    this.store.dispatch(CurrentSurveyActions.goToNextQuestion({
      currentQuestionId: this.currentQuestion.id,
      answer: undefined,
    }));
  }

  handleBackClick() {
    this.store.dispatch(CurrentSurveyActions.goToPreviousQuestion());
  }

}
