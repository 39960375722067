import { createAction, props } from '@ngrx/store';
import { Answer } from 'shared';

export enum CurrentSurveyActionTypes {
  GoToNextQuestion = '[Current Survey] Go to next question',
  GoToPreviousQuestion = '[Current Survey] Go to previous question',
  ResetCurrentSurveyState = '[Current Survey] Reset state',
}

export const goToNextQuestion = createAction(
  CurrentSurveyActionTypes.GoToNextQuestion,
  props<{
    currentQuestionId: string,
    answer: Answer | undefined,
  }>(),
);

export const goToPreviousQuestion = createAction(CurrentSurveyActionTypes.GoToPreviousQuestion);
export const resetCurrentSurveyState = createAction(CurrentSurveyActionTypes.ResetCurrentSurveyState);
