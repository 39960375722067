import { Component } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AuthActions, AuthSelectors, AuthUINavigationActions, BaseAppState, ProfilePageComponent } from 'shared';

import * as StudyParticipationNavigationActions from '../../../router/actions/study-participation-navigation.actions';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent {

  isLoggedIn$ = this.store.pipe(select(AuthSelectors.isLoggedIn));

  constructor(
    private store: Store<BaseAppState>,
    private modalService: BsModalService,
    private translateService: TranslateService,
  ) { }

  handleSetLanguageClick(lang: string) {
    this.translateService.use(lang);
  }

  handleSurveyClick() {
    this.store.dispatch(StudyParticipationNavigationActions.goToSurvey());
  }

  handleProfileClick() {
    this.modalService.show(ProfilePageComponent);
  }

  handleStartPageClick() {
    this.store.dispatch(StudyParticipationNavigationActions.goToDashboard());
  }

  handleUserSettingsClick() {
    this.store.dispatch(AuthUINavigationActions.goToSettings());
  }

  handleSignUpClick() {
    this.store.dispatch(AuthUINavigationActions.goToSignUp());
  }

  handleLoginClick() {
    this.store.dispatch(AuthUINavigationActions.goToLogin());
  }

  handleLogoutClick() {
    this.store.dispatch(AuthActions.logout());
  }

}
