import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { AsyncAction, handleErrors } from 'shared';

import * as CurrentSurveyApiActions from '../actions/current-survey-api.actions';
import { CurrentSurveyApiService } from '../services/current-survey-api.service';

@Injectable()
export class CurrentSurveyEffects {

  submitAnswers$ = createEffect(() => this.actions$.pipe(
    ofType(CurrentSurveyApiActions.submitAnswers),
    switchMap((action) => this.apiService.submitAnswers(action.answers).pipe(
      handleErrors(() => CurrentSurveyApiActions.submitAnswersFail()),
      map(() => CurrentSurveyApiActions.submitAnswersSuccess()),
      catchError((errorAction: AsyncAction) => of(errorAction)),
    ))),
    { dispatch: true },
  );

  constructor(
    private actions$: Actions,
    private apiService: CurrentSurveyApiService,
  ) { }
}
