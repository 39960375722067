<nav class="navbar navbar-expand navbar-light navbar-border-bottom justify-content-between">
  <div class="container">
    <a class="navbar-brand" href="#">
      <img alt="ESPRIO" src="assets/img/logos/esprio.svg">
    </a>

    <div class="d-flex justify-content-center">
      <ul class="navbar-nav me-auto">
        <li class="nav-item">
          <a class="nav-link" (click)="handleSetLanguageClick('de')">German</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" (click)="handleSetLanguageClick('en')">English</a>
        </li>
      </ul>
    </div>

    <div class="d-flex">
      <ng-container *ngIf="isLoggedIn$ | async">
        <span class="material-icons mr-2 account-item" (click)="handleProfileClick()">account_circle</span>

        <div class="btn-group" dropdown>
          <button 
            dropdownToggle
            class="btn dropdown-toggle d-flex align-items-center"
            aria-controls="dropdown-animated"
            >
            <span class="material-icons">menu</span>
          </button>
          <ul *dropdownMenu class="dropdown-menu dropdown-menu-right">
            <li role="menuitem">
              <a class="dropdown-item" (click)="handleSurveyClick()">{{'navigation.new-submission' | translate}}</a>
            </li>
            <li role="menuitem">
              <a class="dropdown-item" (click)="handleStartPageClick()">{{'navigation.landing-page' | translate}}</a>
            </li>
            <li role="menuitem">
              <a class="dropdown-item" (click)="handleUserSettingsClick()">{{'navigation.settings' | translate}}</a>
            </li>
            <li class="divider dropdown-divider"></li>
            <li role="menuitem">
              <a class="dropdown-item" (click)="handleLogoutClick()">{{'auth.logout' | translate}}</a>
            </li>
          </ul>
        </div>
      </ng-container>

      <ng-container *ngIf="!(isLoggedIn$ | async)">
        <ul class="navbar-nav me-auto">
          <li class="nav-item">
            <a class="nav-link" (click)="handleLoginClick()">{{'auth.login' | translate}}</a>
          </li>
        </ul>
      </ng-container>
    </div>
  </div>
</nav>