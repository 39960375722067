<ng-container *ngIf="currentQuestionGroup$ | async as questionGroup">
  <div class="mb-4">
    <progressbar [animate]="true" [value]="overallQuestionIndex$ | async" [max]="questionAmount$ | async"></progressbar>
    <div class="text-left">
      <span class="my-1 font-weight-bold">{{questionGroup.title}}</span>
      <br>
      <ng-container *ngIf="currentQuestionIndexInQuestionGroup$ | async as currentQuestionIndex">
        <ng-container *ngIf="currentQuestionGroupQuestionAmount$ | async as questionAmount">
          <span>{{'application.question-of' | translate: { 'currentQuestionIndex': currentQuestionIndex, 'questionAmount': questionAmount } }}</span>
        </ng-container>
      </ng-container>
    </div>
  </div>
</ng-container>
