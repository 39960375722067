<div class="row justify-content-center d-flex flex-column align-items-center">
  <div class="slider-answers col-md-10 col-lg-9 col-xl-8 px-4" [ngClass]="(screenHeight$ | async) >= 850 ? '' : 'replies'">
    <div class="slider-value-container d-flex justify-content-center text-center">
      <div class="slider-value">
        <span class="slider-value">{{currentQuestion.choices[selectedIndex].label}}</span>
      </div>
    </div>
    <input
      #slider
      type="range"
      min="0"
      [max]="(currentQuestion.choices.length - 1)"
      [value]="selectedIndex"
      class="slider"
      (input)="onSliderValueChange(slider.value)"
    >
    <div class="slider-labels d-flex flex-row justify-content-between">
      <div *ngFor="let option of currentQuestion.choices; let index = index; let last = last">
        <p *ngIf="index === 0 || last">{{ option.label }}</p>
      </div>
    </div>
  </div>
  <div [ngClass]="(screenHeight$ | async) >= 850 ? 'col-md-10 col-lg-9 col-xl-8' : 'question-action-buttons border-top'">
    <div class="d-flex flex-row mt-4 mt-md-5 justify-content-even justify-content-center">
      <div *ngIf="!(isFirstQuestion$ | async)" class="col-6" [ngClass]="(screenHeight$ | async) >= 850 ? '' : 'col-md-4 col-lg-3'">
        <button class="btn btn-lg button-back w-100" (click)="handleBackClick()">{{'application.back' | translate}}</button>
      </div>
      <div class="col-6" [ngClass]="(screenHeight$ | async) >= 850 ? '' : 'col-md-4 col-lg-3'">
        <button class="btn btn-lg button-next w-100" (click)="handleSliderValueConfirm()">{{(!(isLastQuestion$ | async) ? 'application.next' : 'application.finish') | translate}}</button>
      </div>
    </div>
  </div>
</div>