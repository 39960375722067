import { Component, HostListener, Input, OnChanges } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';
import { Answer, BaseAppState, Choice, Question } from 'shared';

import * as CurrentSurveyActions from '../../../actions/current-survey.actions';
import * as CurrentSurveySelectors from '../../../selectors/current-survey.selectors';

export enum AnswerAssessment {
  Worst = 'Sehr',
  Bad = 'Mäßig',
  Good = 'Wenig',
  Best = 'Überhaupt nicht',
}

@Component({
  selector: 'app-button-answers',
  templateUrl: './button-answers.component.html',
  styleUrls: ['./button-answers.component.scss'],
})
export class ButtonAnswersComponent implements OnChanges {

  @Input() currentQuestion: Question;
  @Input() allowMultiSelect = false;

  preselectedAnswer$ = this.store.pipe(select(CurrentSurveySelectors.getCurrentSurveyAnswerForQuestion));
  isFirstQuestion$ = this.store.pipe(select(CurrentSurveySelectors.getIsFirstQuestion));
  isLastQuestion$ = this.store.pipe(select(CurrentSurveySelectors.getIsLastQuestion));
  screenHeight$: Observable<number> = of(window.innerHeight);

  selectedChoiceIds: Set<string> = new Set();
  AnswerAssessment = AnswerAssessment;

  @HostListener('window:resize', ['$event'])
  onResize(_event: unknown) {
    this.screenHeight$ = of(window.innerHeight);
  }

  constructor(private store: Store<BaseAppState>) { }

  ngOnChanges() {
    this.initializeSelectedChoices();
  }

  handleAnswerButtonClick(choiceId: string) {
    this.toggleChoice(choiceId);
  }

  handleSubmitAnswerButtonClick() {
    this.submitAnswer();
  }

  handleBackClick() {
    this.store.dispatch(CurrentSurveyActions.goToPreviousQuestion());
  }

  handleSkipButtonClick() {
    this.store.dispatch(CurrentSurveyActions.goToNextQuestion({
      currentQuestionId: this.currentQuestion.id,
      answer: undefined,
    }));
  }

  canSubmit() {
    if (this.allowMultiSelect) {
      return true;
    }
    return this.selectedChoiceIds.size > 0;
  }

  getOptionsAssessment(option: Choice): AnswerAssessment | undefined {
    if (option.label === AnswerAssessment.Worst) {
      return AnswerAssessment.Worst;
    }
    if (option.label === AnswerAssessment.Bad) {
      return AnswerAssessment.Bad;
    }
    if (option.label === AnswerAssessment.Good) {
      return AnswerAssessment.Good;
    }
    if (option.label === AnswerAssessment.Best) {
      return AnswerAssessment.Best;
    }

    return undefined;
  }

  private initializeSelectedChoices() {
    this.selectedChoiceIds.clear();

    this.preselectedAnswer$.pipe(
      filter((answer: Answer | undefined) => Boolean(answer)),
      take(1),
      tap((preselectedAnswer: Answer) => {
        preselectedAnswer.choices.forEach((choice) => {
          this.selectedChoiceIds.add(choice);
        });
      }),
    ).subscribe();
  }

  private toggleChoice(choiceId: string) {
    if (!this.selectedChoiceIds.has(choiceId)) {
      if (!this.allowMultiSelect) {
        this.selectedChoiceIds.clear();
      }

      this.selectedChoiceIds.add(choiceId);
    } else {
      this.selectedChoiceIds.delete(choiceId);
    }
  }

  private submitAnswer() {
    const answer: Answer = {
      choices: [...this.selectedChoiceIds],
      answeredAt: new Date().toISOString(),
    };

    this.store.dispatch(CurrentSurveyActions.goToNextQuestion({
      currentQuestionId: this.currentQuestion.id,
      answer: answer,
    }));
  }

}
