<div class="row justify-content-center text-center d-flex flex-column align-items-center">
  <div class="col-md-10 col-lg-9 col-xl-8">
		<form [formGroup]="form" name="answers" #ngForm="ngForm" (ngSubmit)="handleSubmitAnswerButtonClick()" novalidate>
		  <div [ngClass]="(screenHeight$ | async) >= 850 ? '' : 'replies'">
		    <input type="text" class="form-control form-control-lg" formControlName="choice">
		    <div *ngIf="ngForm.submitted && !form.valid" class="custom-invalid-feedback">
		      <div *ngIf="form.controls['choice'].hasError('pattern')">{{'error.date-invalid-format' | translate}}<br></div>
		      <div *ngIf="form.controls['choice'].hasError('future')">{{'error.date-in-future' | translate}}<br></div>
		    </div>
		  </div>
			<div [ngClass]="(screenHeight$ | async) >= 850 ? 'col-md-10 col-lg-9 col-xl-8' : 'question-action-buttons border-top'">
				<div class="d-flex flex-row mt-4 mt-md-5 justify-content-even justify-content-center">
					<div *ngIf="!(isFirstQuestion$ | async)" class="col-6" [ngClass]="(screenHeight$ | async) >= 850 ? '' : 'col-md-4 col-lg-3'">
						<button class="btn btn-lg button-back w-100" (click)="handleBackClick()">{{'application.back' | translate}}</button>
					</div>
					<div class="col-6" [ngClass]="(screenHeight$ | async) >= 850 ? '' : 'col-md-4 col-lg-3'">
						<button type="submit" class="btn btn-lg button-next w-100" [disabled]="!form.controls['choice'].value">{{(!(isLastQuestion$ | async) ? 'application.next' : 'application.finish') | translate}}</button>
					</div>
				</div>
			</div>
		</form>
	</div>
</div>
