export const SURVEY_PATH_ELEMENTS = {
  rootElement: 'survey',
  participantDetailsRoot: 'participant-details',
  root: {
    startElement: 'start',
    questionnaireElement: 'questionnaire',
  },
  participantDetails: {
    participantDetailsElement: ':patientId',
  },
};

export const SURVEY_PATHS = {
  root: {
    start: `/${SURVEY_PATH_ELEMENTS.rootElement}/${SURVEY_PATH_ELEMENTS.root.startElement}`,
    questionnaire: `/${SURVEY_PATH_ELEMENTS.rootElement}/${SURVEY_PATH_ELEMENTS.root.questionnaireElement}`,
  },
  participantDetails: {
    patient: (participantId: string) => `/${SURVEY_PATH_ELEMENTS.participantDetailsRoot}/${participantId}`,
  },
};
