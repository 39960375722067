<div class="container">
  <div class="row justify-content-center d-flex flex-column align-items-center">
    <div class="replies col-md-10 col-lg-9 col-xl-8 mb-5">

      <ng-container *ngIf="!(isSubmissionFinished$ | async)">
        <app-question-detail></app-question-detail>
      </ng-container>

      <ng-container *ngIf="(isSubmissionFinished$ | async)">
        <p>{{'application.thanks-for-submission' | translate}}.</p>
        <button class="btn btn-outline-primary btn-lg mx-sm-2 mt-2 mt-md-3" (click)="handleResetClick()">{{'application.repeat-submission' | translate}}</button>
      </ng-container>

    </div>
  </div>
</div>
<app-async-state-overlay [processKeys]="processKeys"></app-async-state-overlay>